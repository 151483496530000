:root {
  --purple: #3f2180;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  font-size: inherit;
  outline: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
}

input[type='submit'] {
  font-size: inherit;
  background: transparent;
}

.container {
  width: 80%;
  max-width: 1000px;
  margin: auto;
}

header .layer,
section {
  padding: 60px 0;
}

img {
  max-width: 100%;
}

/* text */

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.125rem;
}

h1,
h2,
h3 {
  font-family: 'Dosis', sans-serif;
}

h1,
h2 {
  font-size: 3rem;
  font-weight: 500;
}

h1 {
  color: #fff06c;
  margin: 100px 0 0;
}

h2,
h3 {
  margin-bottom: 20px;
}

h4 {
  color: var(--purple);
}

p {
  font-weight: 500;
  line-height: 140.6%;
  margin: 20px auto 20px;
  color: #222222;
}

/* buttons */

.btn1 {
  color: var(--yellow);
}

.btn2 {
  color: var(--turquoise);
}

.btn3 {
  color: var(--purple);
}

.btn a,
.btn3 {
  text-decoration: none;
  color: inherit;
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
}

.btn {
  --yellow: #fff06c;
  --pink: #ff82b1;
  --white: #fff;
  --turquoise: #44fece;
  position: relative;
  padding: 15px 70px;
  /*padding-right: 3.1rem;*/
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
  border: solid 5px;
  border-radius: 50px;
  display: inline-block;
  margin-top: 20px;
}

.btn:before,
.btn:after {
  content: '';
  position: absolute;
  transition: inherit;
  z-index: -1;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: inherit;
}

.btn:hover {
  background: var(--pink);
  border-color: var(--pink);
  color: var(--white);
  transition-delay: 0.5s;
}

.btn:hover:after {
  background: var(--yellow);
  transition-delay: 0.35s;
}

.btn:after {
  left: 0;
}

.btn:hover:before,
.btn:hover:after {
  width: 100%;
}

.stores {
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
}

/*HEADER*/

header {
  background: center / cover no-repeat url('/images/slider-background.svg');
  text-align: center;
  position: relative;
  z-index: 0;
}

header .layer {
  background-color: rgba(0, 0, 0, 0.5);
}

header p {
  color: white;
  max-width: 600px;
}

header .stores {
  justify-content: center;
}

/* COMMENT CA MARCHE ? */

.section1 h2,
.section3 h2 {
  text-align: center;
  color: var(--purple);
}

.days {
  text-align: center;
  margin-top: 40px;
}

h3 {
  font-size: 2.5rem;
  font-weight: 600;
}

.section1 h3 {
  color: #ff82b1;
}

.days img {
  margin-bottom: 10px;
}

/* OSE LA RENCONTRE */

.section2 {
  background: #e3faf2;
}

.section2 h3 {
  color: var(--purple);
}

.section2 .article2 {
  margin: 60px 0;
}

/* OU CA SE PASSE ? */

.section3 {
  background: left 0% / cover url('/images/pattern.svg');
}

.section3 iframe {
  margin-top: 20px;
}

/* PARTICIPE A LA PROCHAINE SOIREE */

.section4 .container {
  display: flex;
  position: relative;
  z-index: 0;
}

.section4 .text {
  position: relative;
  z-index: 1;
}

.section4 {
  background: #693b91;
  overflow: hidden;
  position: relative;
}

.section4 h2 {
  color: #44fece;
}

.section4 p {
  color: white;
}

/* INSCRIPTION */

.subscribe-block {
  margin: auto;
  position: relative;
  color: var(--purple);
}

.subscribe-block h2 {
  text-align: center;
}

.subscribe-block form {
  font-weight: 600;
  margin-top: 40px;
}

::placeholder {
  font-family: 'Open Sans', sans-serif;
  opacity: 0.4;
}

.cross {
  position: absolute;
  right: 20px;
  top: 20px;
}

.email-form label,
.email-form input {
  display: block;
}

input.email,
input.password {
  width: 100%;
  margin: 10px 0 30px;
  border: none;
  padding: 16px 15px;
}

.field {
  display: flex;
  position: relative;
}

.eyeicon {
  position: absolute;
  right: 15px;
  top: 20px;
  height: 12px;
  cursor: pointer;
}

.subscribe-block .btn {
  margin: auto;
}

/* FOOTER */

footer {
  background: var(--purple);
  padding: 35px 0;
  display: flex;
  flex-wrap: wrap;
}

footer p,
footer a {
  color: white;
  text-decoration: none;
  font-size: 0.875rem;
}

/* DESKTOP ONLY */

@media screen and (min-width: 769px) {
  .appstore {
    margin-right: 30px;
  }

  .days {
    display: flex;
    justify-content: space-between;
  }

  .days div {
    width: 28%;
  }

  .section2 article {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section2 article p {
    max-width: 400px;
    margin: 0;
  }

  .section2 article img {
    width: 50%;
  }

  .section2 .article1 .text,
  .duo .text {
    margin-right: 30px;
  }

  .section2 .article2 .text {
    margin-left: 30px;
  }

  .mobileimg {
    display: none;
  }

  .section4 .text {
    width: 60%;
  }

  .smartphoneimg {
    position: absolute;
    bottom: -290px;
    right: -100px;
    max-width: 360px;
    transform: rotate(15deg);
  }

  .subscribe-block {
    width: 600px;
    max-width: 500px;
    min-width: 300px;
    padding: 50px 120px;
  }

  footer .container,
  .logo-copyright,
  .contact-social,
  .social {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerlogo {
    margin-right: 30px;
  }

  .instagram,
  .facebook,
  .contact {
    margin-left: 30px;
  }

  .contact {
    min-width: 40px;
  }
}

/* MOBILE ONLY */

@media screen and (max-width: 817px) {
  .appstore {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .stores {
    flex-direction: column;
  }

  .stores img {
    width: 185px;
  }

  .monday {
    margin: 40px 0;
  }

  .section2 .text {
    text-align: center;
  }

  .desktopimg,
  .hide {
    display: none;
  }

  .mobileimg {
    width: 100%;
    margin-bottom: -63px;
  }

  .mobileimg .drinks {
    margin-top: 40px;
  }

  .section4 {
    text-align: center;
  }

  .subscribe-block {
    width: 100%;
    max-width: 500px;
    padding: 40px 5%;
  }

  footer div {
    text-align: center;
  }

  footer .container {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-social {
    width: 100%;
    order: 1;
    margin-bottom: 30px;
  }

  .logo-copyright {
    width: 100%;
    order: 2;
  }

  .facebook {
    margin-right: 30px;
  }

  span.line {
    display: block;
    margin-top: 5px;
  }
}
